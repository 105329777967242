<!-- 单品管理 -->
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="newCreate()">新建单品</a-button>
  <a-table
    bordered
    rowKey="logicId"
    size="middle"
    :columns="columns"
    :data-source="source"
    :pagination="pagination"
    :loading="pagination.loading"
  >
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal
    :title="modifyModal.title"
    v-model:visible="modifyModal.visible"
    :confirm-loading="modifyModal.loading"
    @ok="handleOk"
    @cancel="handlerCancel"
  >
    <a-spin v-if="modifyModal.status" />
    <a-form
      v-else
      ref="formRef"
      :model="modifyModal.data"
      :rules="modifyModal.rules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
    >
      <a-form-item label="优惠星球" name="starId">
        <a-select
          v-model:value="modifyModal.data.starId"
          style="width: 120px"
          placeholder="请选择"
          @change="change2()"
        >
          <a-select-option
            v-for="(item, i) in modifyModal.selects1"
            :key="i"
            :value="item.logicId"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="二级类目" name="goodsTypeTwo">
        <a-select
          v-model:value="modifyModal.data.goodsTypeTwo"
          style="width: 120px"
          placeholder="请选择"
          @change="change3()"
        >
          <a-select-option
            v-for="(item, i) in modifyModal.selects2"
            :key="i"
            :value="item.logicId"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="三级类目" name="goodsTypeThree">
        <a-select
          v-model:value="modifyModal.data.goodsTypeThree"
          style="width: 120px"
          placeholder="请选择"
          @change="change4()"
        >
          <a-select-option
            v-for="(item, i) in modifyModal.selects3"
            :key="i"
            :value="item.logicId"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="关联品牌" name="brandId">
        <a-select v-model:value="modifyModal.data.brandId" style="width: 120px" placeholder="请选择">
          <a-select-option
            v-for="(item, i) in modifyModal.selects4"
            :key="i"
            :value="item.logicId"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="型号名称" name="model">
        <a-input v-model:value="modifyModal.data.model" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRaw } from 'vue'
import { saveOrUpdate, page, detail } from '@/api/item'
import { page as paged } from '@/api/brand'
import { page as pagesc } from '@/api/category'
import { getStarList } from '@/api/planet'
import { message } from 'ant-design-vue'
import { query as querys } from '@/api/public'
import { page as pageBrand } from '@/api/brand'
export default {
  setup() {
    const { formRef, columns, pagination, source, modifyModal, editCates, handlerCancel, handleOk, newCreate, change2, change3, change4 } = useCategory2Manage()
    return { formRef, columns, pagination, source, modifyModal, editCates, handlerCancel, handleOk, newCreate, change2, change3, change4 }
  },
};
function useCategory2Manage() {
  // 表格标题配置
  const columns = [
    { title: 'ID', dataIndex: 'logicId', width: '15%' },
    { title: '标题', dataIndex: 'title', width: '65%' },
    { title: '操作', dataIndex: 'operation', width: '20%', slots: { customRender: 'operation' } },
  ]
  const rulesMod = { // 修改规则
    starId: { required: true, message: '请选择优惠星球', trigger: 'blur' },
    // goodsTypeTwo :{ required: true, message: '请选择二级类目', trigger: 'blur' },
    // goodsTypeThree :{ required: true, message: '请选择三级类目', trigger: 'blur' },
    // brandId :{ required: true, message: '请选择关联品牌', trigger: 'blur' },
  }
  const source = ref([]) // 显示的表格数据
  const formRef = ref();
  // ， ， 
  /**
   * title: modal标题
   * visible: modal是否出现
   * loading：modal点击提交按钮是否加载
   * status：modal出现之后是否加载
   * form: 表单数据对象
   * data：网络请求的数据
   * rules: 表单提交轨辙
   * selects: 选择数据
   */
  const modifyModal = ref({
    title: '', visible: false, loading: false, status: true, form: {}, data: {}, rules: rulesMod,
    selects1: [],
    selects2: [],
    selects3: [],
    selects4: [],
  })
  const tree = ref({})

  const pagination = ref({ // 分页配置
    loading: true,
    position: 'bottom',
    total: 0, // 条数
    defaultPageSize: 20, // 默认分页条数
    pageSizeOptions: ['5', '10', '15', '20', '30'],
    showSizeChanger: true, // 是否可以改变 pageSize
    showQuickJumper: true, // 是否可以快速跳转至某页
    showTotal: total => `共 ${total} 条`,
    hideOnSinglePage: false, // 只有一页时是否隐藏分页器
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows)
      pagination.value.loading = true;
      getList(selectedRowKeys, selectedRows)
    }
  })
  // 加载修改页面
  const loadingMod = async (logicId) => {
    const { data, status } = await detail({ id: logicId })

    if (status == '200') {
      const { model, brandId, goodsTypeThree, goodsTypeTwo, starId, logicId } = data
      modifyModal.value.data = { model, brandId, goodsTypeThree, goodsTypeTwo, starId, logicId } // 将要修改的数据加载到modal
      modifyModal.value.status = false //停止modal加载状态
      return
    }
    message.warning('系统繁忙');
  }
  // 触发修改页面
  const editCates = (row) => {
    modifyModal.value.title = '修改单品'
    modifyModal.value.visible = true // 打开modal
    const { logicId } = row
    loadingMod(logicId)
  }
  // 触发新建页面
  const newCreate = () => {
    modifyModal.value.title = '新建单品'
    modifyModal.value.visible = true // 打开modal
    modifyModal.value.data = {} // 初始化modal重的表格数据
    modifyModal.value.status = false // 停止modal加载状态
    modifyModal.value.loading = false // 提交按钮加载状态
  }

  const handlerCancel = async () => {
    modifyModal.value.status = true
    modifyModal.value.loading = false // 提交按钮加载状态
  }
  const handleOk = () => {
    formRef.value.validate().then(() => {
      const exce = async () => {
        modifyModal.value.loading = true
        const { title, model, brandId, goodsTypeThree, goodsTypeTwo, starId, logicId } = modifyModal.value.data
        const { status, msg } = await saveOrUpdate({ title, model, brandId, goodsTypeThree, goodsTypeTwo, starId, logicId })
        if (status == '200') {
          message.success(msg)
          modifyModal.value.loading = false
          modifyModal.value.visible = false
          getList()
          return
        }
        modifyModal.value.loading = false
        message.error(msg)
      }
      exce()
    })
      .catch(error => {
        console.log('error', error);
      });

  }
  // 获取分页数据
  async function getList(pageNo = "1", pageSize = "20", level = "2") {
    const { data, status } = await page({ pageNo, pageSize, level })
    if (status == '200') {
      const { total, dataOnThisPage } = data
      pagination.value.total = total || 0
      source.value = dataOnThisPage || []
    }
    pagination.value.loading = false;

  }
  async function change2() {
    const { data } = await querys({ starId: modifyModal.value.data.starId, level: '2' })
    modifyModal.value.data.goodsTypeTwo = ""
    modifyModal.value.data.goodsTypeThree = ""
    modifyModal.value.data.brandId = ""

    modifyModal.value.selects2 = data
    modifyModal.value.selects3 = []
    modifyModal.value.selects4 = []

  }
  async function change3() {
    const { data } = await querys({ parentId: modifyModal.value.data.goodsTypeTwo, level: '3' })

    modifyModal.value.selects3 = data
    modifyModal.value.data.goodsTypeThree = ""
    modifyModal.value.data.brandId = ""
    modifyModal.value.selects4 = []

  }
  async function change4() {
    const { data } = await pageBrand({ pageNo: "1", pageSize: "100" })
    console.log("start===============");
    console.log(JSON.stringify(data, null, 2));
    modifyModal.value.data.brandId = ""
    modifyModal.value.selects4 = data.dataOnThisPage
    console.log(data.dataOnThisPage)

  }
  onMounted(getList)
  onMounted(async () => {
    // 星球
    const star = await getStarList({ pageNum: 1, pageSize: 100 })
    // 二级类目
    const two = await pagesc({ pageNo: "1", pageSize: "1000", level: "2" })
    // //三级类目
    const three = await pagesc({ pageNo: "1", pageSize: "1000", level: "3" })
    const branda = await paged({ pageNo: "1", pageSize: "1000" })
    modifyModal.value.selects1 = star.data.dataOnThisPage || []
    modifyModal.value.selects2 = two.data.dataOnThisPage || []
    modifyModal.value.selects3 = three.data.dataOnThisPage || []
    modifyModal.value.selects4 = branda.data.dataOnThisPage || []
    // const query = await querys({level: "1"})
    console.log(JSON.stringify(tree, null, 2))
  })
  return { formRef, columns, pagination, source, modifyModal, editCates, handlerCancel, handleOk, newCreate, change2, change3, change4 }
}
</script>

<style>
</style>
