import { request, requestJSON } from '../utils/axios'
import { stringify } from 'qs'

// 新增或修改二、三级分类
export function saveOrUpdate (params) {
  return requestJSON({
    url: `/wuan/admin/star/brand/saveOrUpdate`,
    method: 'post',
    data: params
  })
}
export function page (params) {
  return request({
    url: `/wuan/admin/star/brand/page?${stringify(params)}`,
    method: 'get'
  })
}
export function detail (params) {
  return request({
    url: `/wuan/admin/star/brand/detail?${stringify(params)}`,
    method: 'get'
  })
}
