import { request, requestForm } from '../utils/axios'
import { stringify } from 'qs'

// 新增或修改二、三级分类
export function saveOrUpdate (params) {
  return request({
    url: `/wuan/admin/star/item/saveOrUpdate?${stringify(params)}`,
    method: 'post',
    data: params
  })
}
export function page (params) {
  return request({
    url: `/wuan/admin/star/item/page?${stringify(params)}`,
    method: 'get'
  })
}
export function detail (params) {
  return request({
    url: `/wuan/admin/star/item/detail?${stringify(params)}`,
    method: 'get'
  })
}
